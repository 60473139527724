export const getAuthToken = () => {
  const match = document.cookie.match(/(^| )auth.token=([^;]+)/);
  return match ? match[2] : null;
};

export const getUser = () => {
  const match = document.cookie.match(/(^| )auth.user=([^;]+)/);
  return match ? match[2] : null;
};

export const getCookie = (name) => {
  const match = document.cookie.match(`(^| )${name}=([^;]+)`);
  return match ? match[2] : null;
};

export const getStorage = (key) => {
  const data = window?.localStorage.getItem(key);
  return data;
};
