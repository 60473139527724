import { useScript } from './useScript';

export const useLoadGoogleApis = (googleApiKey) => {
  useScript({
    id: 'google-maps',
    position: document.querySelector('head'),
    src: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`,
    async: true,
    validateOnlyProduction: false,
  });
};
