import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getUserByToken } from '../../../../services/authServices';
import { getUser, getStorage } from '../../../../utils/cookies';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetProduction: '[Set Production] Action',
  SetIsEnable: '[Set Enabled] Action',
  SetWalletConnected: '[Set WalletConnected] Action',
  SetSecurityCode: '[Set TwoSFcode] Action',
  SetCurrentCountry: '[Set CurrentCountry] Action',
  SetCurrentSubOptionMenuSelected: '[Set CurrentSubOptionMenuSelected] Action',
  ResetCurrentSubOptionMenuSelected:
    '[ResetCurrentSubOptionMenuSelected] Action',
  SetCurrentLocationCoordUser: '[Set CurrentLocationCoordUser] Action',
  InitializeAuth: '[Set auth] Action',
};

const initialAuthState = {
  user: getUser(),
  authToken: getStorage('auth.token'),
  isAgent: getStorage('auth.isAgent') === 'true',
  isProduction: false,
  enabledProduction: undefined,
  walletConnected: null,
  securityCode: null,
  currentCountry: 'ARG',
  currentSubOptionMenuSelected: null,
  currentLocationCoordUser: {
    longitude: -58.4411745,
    latitude: -34.6420621,
    loading: true,
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
    timestamp: Date.now(),
  },
};

export const reducer = persistReducer(
  {
    storage,
    key: 'v1-km-auth',
    whitelist: [
      'auth.user',
      'auth.token',
      'auth.isAgent',
      'isProduction',
      'enabledProduction',
      'walletConnected',
      'securityCode',
      'currentCountry',
      'currentSubOptionMenuSelected',
      'currentLocationCoordUser',
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, isAgent } = action.payload;

        return { ...state, authToken, isAgent, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { ...state, authToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;

        return { ...state, user };
      }

      case actionTypes.SetProduction: {
        const { isProduction } = action.payload;

        return { ...state, isProduction };
      }

      case actionTypes.SetIsEnable: {
        const { enabledProduction } = action.payload;

        return { ...state, enabledProduction };
      }

      case actionTypes.SetWalletConnected: {
        const { walletConnected } = action.payload;

        return { ...state, walletConnected };
      }

      case actionTypes.SetSecurityCode: {
        const { securityCode } = action.payload;

        return { ...state, securityCode };
      }

      case actionTypes.SetCurrentCountry: {
        const { currentCountry } = action.payload;

        return { ...state, currentCountry };
      }

      case actionTypes.SetCurrentSubOptionMenuSelected: {
        const { subOptionSelected } = action.payload;

        return { ...state, currentSubOptionMenuSelected: subOptionSelected };
      }

      case actionTypes.ResetCurrentSubOptionMenuSelected: {
        return { ...state, currentSubOptionMenuSelected: null };
      }

      case actionTypes.SetCurrentLocationCoordUser: {
        const { locationCoordUser } = action.payload;

        return {
          ...state,
          currentLocationCoordUser: {
            ...state.currentLocationCoordUser,
            ...locationCoordUser,
          },
        };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  login: (authToken, isAgent) => ({
    type: actionTypes.Login,
    payload: { authToken, isAgent },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user) => ({
    type: actionTypes.SetUser,
    payload: { user },
  }),
  setProduction: (isProduction) => ({
    type: actionTypes.SetProduction,
    payload: { isProduction },
  }),
  setEnabled: (enabledProduction) => ({
    type: actionTypes.SetIsEnable,
    payload: { enabledProduction },
  }),
  setWalletConnected: (walletConnected) => ({
    type: actionTypes.SetWalletConnected,
    payload: { walletConnected },
  }),
  SetSecurityCode: (securityCode) => ({
    type: actionTypes.SetSecurityCode,
    payload: { securityCode },
  }),
  setCurrentCountry: (currentCountry) => ({
    type: actionTypes.SetCurrentCountry,
    payload: { currentCountry },
  }),
  setCurrentSubOptionMenuSelected: (subOptionSelected) => ({
    type: actionTypes.SetCurrentSubOptionMenuSelected,
    payload: { subOptionSelected },
  }),
  resetCurrentSubOptionMenuSelected: () => ({
    type: actionTypes.ResetCurrentSubOptionMenuSelected,
  }),
  setCurrentLocationCoordUser: (locationCoordUser) => ({
    type: actionTypes.SetCurrentLocationCoordUser,
    payload: { locationCoordUser },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {
      data: {
        data: { user },
      },
    } = yield getUserByToken();

    yield put(actions.fulfillUser(user));

    const { active = false } = user.production.find(
      (item) => item.available_to === 'hooks',
    );

    yield put(actions.setProduction(active));
  });
}
