import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions as authActions } from '../modules/Auth/_redux/authRedux';
import { getStorage } from '../../utils/cookies';

export const useInitializeLoginByLocaStorage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const authToken = getStorage('auth.token');
    const isAgent = getStorage('auth.isAgent') === 'true';

    if (authToken) {
      dispatch(authActions.login(authToken, isAgent));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
