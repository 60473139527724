import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, AppBar, Toolbar, Box } from '@material-ui/core';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import LinkRouter from '../LinkRouter';
import MenuMobile from '../MenuMobile';
import CountryMenu from '../CountryMenu';

import { toAbsoluteUrl } from '../../../utils';
import { PATHS } from '../../../constants/paths';
import { HEADER_MENU_ITEMS } from '../../../constants/headerMenuItems';
import * as auth from '../../modules/Auth/_redux/authRedux';

import HeaderDropdown from './Dropdown';

import styles from './styles';

const useStyles = makeStyles(styles);

const Header = ({ logout }) => {
  const classes = useStyles();
  const { authToken, isAgent } = useSelector((state) => state.auth);
  const { t } = useTranslation('common');
  const history = useHistory();

  const redirectToLogin = useCallback(() => {
    history.push(PATHS.login.localPath);
  }, [history]);

  const redirectToRegister = useCallback(() => {
    history.push(PATHS.registration.localPath);
  }, [history]);

  const handleLogout = useCallback(() => {
    window?.localStorage.removeItem('auth.token');
    window?.localStorage.removeItem('auth.isAgent');

    logout();

    redirectToLogin();
  }, [logout, redirectToLogin]);

  return (
    <AppBar position="fixed" className={classes.root} id="header-app">
      <Toolbar disableGutters classes={{ regular: classes.toolbar }}>
        <LinkRouter
          to={PATHS.home.path}
          color="inherit"
          underline="none"
          className={classes.mainLogo}
        >
          <Box
            component="img"
            src={toAbsoluteUrl('/assets/images/logo2.svg')}
            alt="Logo"
          />
        </LinkRouter>

        {!isAgent && (
          <Box className={classes.navBar}>
            {HEADER_MENU_ITEMS.map(({ label, data, dropdownMenuMinWidth }) => (
              <HeaderDropdown
                key={`header-dropdown-${label}`}
                label={label}
                data={data}
                menuMinWidth={dropdownMenuMinWidth}
              />
            ))}
          </Box>
        )}

        <Box className={classes.actions}>
          <CountryMenu positionLeftMobile />

          <Box className={classes.authContainer}>
            {!authToken && (
              <>
                <button
                  type="button"
                  className={classNames(
                    classes.authBtn,
                    classes.authBtnOutlined,
                  )}
                  onClick={redirectToLogin}
                >
                  {t('header.login')}
                </button>

                <button
                  type="button"
                  color="secondary"
                  onClick={redirectToRegister}
                  className={classNames(
                    classes.authBtn,
                    classes.authBtnSecondary,
                  )}
                >
                  {t('header.createAccount')}
                </button>
              </>
            )}

            {authToken && (
              <button
                type="button"
                className={classNames(
                  classes.authBtn,
                  classes.authBtnIcon,
                  classes.authBtnOutlined,
                )}
                onClick={handleLogout}
              >
                <img
                  src={toAbsoluteUrl('/assets/icons/exit-purple.svg')}
                  alt="Logout"
                />

                {t('header.logOut')}
              </button>
            )}
          </Box>

          {!isAgent && <MenuMobile />}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, auth.actions)(Header);
